<template>
  <section class="gl-section-block__wrapper">
    <div
      v-if="$slots.title"
      class="gl-section-block__title-wrapper"
    >
      <slot name="title" />
    </div>
    <div
      v-if="$slots.body"
      class="gl-section-block__body-wrapper"
    >
      <slot name="body" />
    </div>
  </section>
</template>

<script>
export default {
  name: 'Section',
}
</script>
<style>
.gl-section-block__title-wrapper {
  font-size: 24px;
  font-weight: 600;
  line-height: 32px;
  margin-bottom: 24px;
}
.gl-section-block__body-wrapper {
  padding: 16px;
  background-color: var(--white);
  border-radius: var(--Corner-radius-L);
  border: 1px solid var(--Accent-Coton-Grey);
}
@media (max-width: 767px) {
  .gl-section-block__title-wrapper {
    font-size: 20px;
    font-weight: 700;
    margin-bottom: 16px;
  }
}
</style>
