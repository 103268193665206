<template>
  <div>
    <div>
      <div class="flex mb-4 flex-wrap gap-4 t-column">
        <div class="flex-2 fullwidth">
          <Section class="mb-4">
            <template #title>
              <div>
                Transaction Information
              </div>
            </template>

            <template #body>
              <div
                v-if="!txData.tx_hash && !txDataLoading"
                class="mb-4 pa-2"
              >
                There are not transaction for this hash
              </div>
              <div class="pa-2">
                <InfoBlock
                  class="mb-4"
                  flex-second-column-two
                  :inline-view="isMobile ? false : true"
                  label="TX HASH"
                  :loading="txDataLoading"
                  :value="txData.tx_hash"
                  word-break-value
                />
                <InfoBlock
                  class="mb-4"
                  flex-second-column-two
                  :inline-view="isMobile ? false : true"
                  label="Total amount"
                  :loading="txDataLoading"
                  :rate="txData
                    && txData.inputsAmount
                    && formatByPrice(txData.inputsAmountPrice)"
                  rate-time="historical"
                  :rate-timestamp="txData && txData.inputsAmountPriceTimestamp"
                  :value="txData
                    && txData.formattedTotalAmount"
                />
                <InfoBlock
                  class="mb-4"
                  flex-second-column-two
                  :inline-view="isMobile ? false : true"
                  label="Block"
                  :loading="txDataLoading"
                  :value="txData
                    && txData.blockHeight
                    && toComaSeparate(String(txData.blockHeight))"
                />
                <InfoBlock
                  class="mb-4"
                  flex-second-column-two
                  :inline-view="isMobile ? false : true"
                  label="Total inputs"
                  :loading="txDataLoading"
                  :value="txData
                    && toComaSeparate(String(txData.totalInputs))"
                />
                <InfoBlock
                  class="mb-4"
                  flex-second-column-two
                  :inline-view="isMobile ? false : true"
                  label="Total outputs"
                  :loading="txDataLoading"
                  :value="txData
                    && toComaSeparate(String(txData.totalOutputs))"
                />
                <InfoBlock
                  class="mb-4"
                  flex-second-column-two
                  :inline-view="isMobile ? false : true"
                  label="Timestamp"
                  :loading="txDataLoading"
                  :value="txData
                    && txData.timestamp
                    && formatDate(txData.timestamp * 1000, 'dd.MM.yyyy HH:mm')"
                />
              </div>
            </template>
          </Section>
        </div>
        <div class="flex-1 report-risk-score-wrapper">
          <Section class="mb-4">
            <template #title>
              <div>
                Risk Score
              </div>
            </template>

            <template #body>
              <div class="pa-3 m-pa-2">
                <GlProgressBarChart
                  :calculation-loading="calculationLoading"
                  :total-funds="totalFunds"
                />
                <div
                  v-if="txRiskySources.incoming && txRiskySources.incoming.length > 0"
                  class="mt-4 mb-4"
                >
                  <strong>{{ formatShare(percent) }}</strong> of funds comes from risky sources
                </div>
                <div
                  v-else
                  class="mt-4 mb-4"
                >
                  No risky sources were found
                </div>

                <div
                  v-if="!calculationLoading"
                  class="mb-5"
                >
                  <p
                    v-if="percent * 100 > 0 || addressAreUnidentified || messages.length > 0"
                    class="sidebar__analytics-label"
                  >
                    AML RISK DETECTED
                  </p>
                  <StatusBlock
                    v-if="percent * 100 > 0"
                    class="mb-2"
                    :label="`${formatShare(percent)} of funds in this transaction are from risky sources`"
                  />
                  <StatusBlock
                    v-if="addressAreUnidentified"
                    class="mb-2"
                    label="More than 75% of sources for this transaction are unidentified"
                  />
                  <StatusBlock
                    v-for="(msg, index) in messages"
                    :key="index"
                    class="mb-2"
                    :label="msg"
                  />
                </div>
                <RiskScoreDisclaimer />
                <button
                  class="gl-button gl-button--dark gl-button--padder gl-button--full mt-3"
                  :disabled="txDataLoading"
                  @click="explore(txData.tx_hash)"
                >
                  Explore graph
                </button>
              </div>
            </template>
          </Section>
        </div>
      </div>
      <SectionBlock class="mb-4">
        <template #title>
          Risk Exposure
        </template>
        <template #body>
          <template v-if="appConfig.VUE_APP_SCORE_CHART_DONUT">
            <div class="flex mb-4 flex-wrap pl-2 pr-2 m-column gap-4">
              <div class="flex-1 m-fullwidth">
                <PieDataList
                  :data="allDataSource.incoming"
                  :loading="calculationLoading"
                  title="By Type"
                  track-by-label="funds.type"
                  track-by-label-support="funds.name"
                />
              </div>
              <div class="flex-1 m-fullwidth">
                <PieDataList
                  :data="allDataSourceByOwner.incoming"
                  :loading="calculationLoading"
                  title="By Owner"
                  track-by-label="owner"
                />
              </div>
            </div>
          </template>
          <template v-else>
            <ReportBarsWrapper
              :all-data-source="allDataSource"
              :all-data-source-by-owner="allDataSourceByOwner"
              :loading="calculationLoading"
              :pure-sources="pureSources"
            />
          </template>
        </template>
      </SectionBlock>
    </div>
   
    <div
      v-if="appConfig.VUE_APP_SCORE_CHART_DONUT"
      class="report-block-wrap"
    >
      <div
        v-if="calculationLoading"
        class="ma-3 bold fs-18"
      >
        Loading...
      </div>
      <div v-else>
        <div
          v-if="!riskySourcesResult.length && !unknownSourcesResult.length &&
            !knownSourcesResult.length"
          class="ma-3 bold fs-18"
        >
          <div v-if="getActiveExposurePart.value == 'incoming'">
            {{ Object.keys(getActiveTypeEntityItem).length ?
              'There is no sources information available with the chosen type or owner. Please select a different option to view the sources of funds.' :
              'There is no sources information available.' }}
          </div>
          <div v-if="getActiveExposurePart.value == 'outgoing'">
            {{ Object.keys(getActiveTypeEntityItem).length ?
              'There is no destination information available with the chosen type or owner. Please select a different option to view the destinations of funds.' :
              'There is no destination information available.' }}
          </div>
        </div>
        <txRiskyTable
          v-if="riskySourcesResult.length > 0"
          class="mb-5"
          :data="riskySourcesResult"
          :has-only-one-source="sourceConfigsByDirection[activeExposurePart.value]?.hasOnlyOneSource"
        />
        <txUnknownTable
          v-if="unknownSourcesResult.length > 0"
          class="mb-5"
          :data="unknownSourcesResult"
          :has-only-one-source="sourceConfigsByDirection[activeExposurePart.value]?.hasOnlyOneSource"
        />
        <txKnownTable
          v-if="knownSourcesResult.length > 0"
          :data="knownSourcesResult"
          :has-only-one-source="sourceConfigsByDirection[activeExposurePart.value]?.hasOnlyOneSource"
        />
      </div>
    </div>
    <ReportTablesWrapper
      v-else
      :currencies-by-direction="currenciesByDirection"
      :loading="calculationLoading"
      :pure-sources="pureSources"
      report-type="tx"
    />
  </div>
</template>

<script>
// Components
import InfoBlock from '@/components/gl-info-block'
import Section from '@/components/gl-section'
import SectionBlock from '@/components/gl-section-block'
import StatusBlock from "@/pages/report/components/StatusBlock";
import txRiskyTable from "@/pages/report/components/TxRiskyTable";
import txKnownTable from "@/pages/report/components/TxKnownTable";
import txUnknownTable from "@/pages/report/components/TxUnknownTable";
import GlProgressBarChart from "@/components/charts/gl-progress-bar-chart";
import RiskScoreDisclaimer from "@/pages/report/components/RiskScoreDisclaimer";
import ReportBarsWrapper from '@/pages/report/components/bars/ReportBarsWrapper'
import ReportTablesWrapper from '@/pages/report/components/table/ReportTablesWrapper'
// Utils
import {formatDate} from "@/utils/format-date";
import {toComaSeparate} from "@/utils/formatNumber";
import {formatBtcAmount} from "@/utils/format-btc-amount";
import {findColorByTypeScore, hex2rgba} from "@/utils/cytoskape-ui-rules";
import { formatFunds, filterSourceResultByEntityType, } from "@/utils/report-data-formatter";
import { formatByPrice } from "@/utils/format-by-price";
import { formatShare } from '@/utils/sourcesFormatter'
// Vuex
import { mapState, mapGetters } from "vuex";
//mixins
import deviceWidthMixin from '@/assets/mixins/deviceWidthMixin'
import PieDataList from '@/pages/report/components/PieDataList.vue'
import appConfig from '@/utils/appConfig'

export default {
  components: {
    PieDataList,
    InfoBlock,
    Section,
    StatusBlock,
    txRiskyTable,
    txKnownTable,
    txUnknownTable,
    GlProgressBarChart,
    RiskScoreDisclaimer,
    ReportBarsWrapper,
    ReportTablesWrapper,
    SectionBlock
  },
  mixins: [deviceWidthMixin],
  props: {
    sourceConfigsByDirection: {
      type: Object,
      default: () => ({})
    },
    txData: {
      type: Object,
      default: () => ({})
    },
    allDataSource: {
      type: [Array, Object],
      default: () => []
    },
    pureSources: {
      type: Object,
      default: () => ({}),
    },
    currenciesByDirection: {
      type: Object,
      default: () => ({}),
    },
    txRiskySources: {
      type: [Array, Object],
      default: () => []
    },
    txKnownSources: {
      type: [Array, Object],
      default: () => []
    },
    txUnknownSources: {
      type: [Array, Object],
      default: () => []
    },
    allDataSourceByOwner: {
      type: [Array, Object],
      default: () => []
    },
    messages: {
      type: Array,
      default: () => []
    },
    txDataLoading: {
      type: Boolean,
      default: false
    },
    calculationLoading: {
      type: Boolean,
      default: false
    },
    percent: {
      type: [Number, String],
      default: 0
    },
    totalFunds: {
      type: [Number, String],
      default: ''
    },
  },
  data() {
    return {
      RISK_POINT: 55,
    }
  },
  computed: {
    appConfig() {
      return appConfig
    },
    ...mapState('analytics', ['coinData']),
    ...mapGetters('directions', [
      'getActiveExposurePart',
      'getActivePartBar',
      'getActiveTypeEntityItem',
    ]),
    riskySourcesResult() {
      return this.filterSourceResultByEntityType(
        this.txRiskySources[this.getActiveExposurePart.value],
        this.getActiveTypeEntityItem,
        this.getActivePartBar.value,
      ) || []
    },
    knownSourcesResult() {
      return this.filterSourceResultByEntityType(
        this.txKnownSources[this.getActiveExposurePart.value],
        this.getActiveTypeEntityItem,
        this.getActivePartBar.value,
      )|| []
    },
    unknownSourcesResult() {
      return this.filterSourceResultByEntityType(
        this.txUnknownSources[this.getActiveExposurePart.value],
        this.getActiveTypeEntityItem,
        this.getActivePartBar.value,
      )|| []
    },
    addressAreUnidentified() {
      const sum = this.txUnknownSources.incoming && this.txUnknownSources.incoming.reduce(
        (acc, { share }) => acc + share,
        0,
      )

      return sum * 100 >= 75
    },
  },
  methods: {
    formatShare,
    formatDate,
    toComaSeparate,
    formatFunds,
    formatBtcAmount,
    findColorByTypeScore,
    hex2rgba,
    formatByPrice,
    filterSourceResultByEntityType,
    explore(tx) {
      const { href } = this.$router.resolve({ name: 'analytics', query: { tx, type: this.coinData.key } })
      window.open(href, '_blank')
    },
  },
}
</script>

<style>
.wallet-tx-wrap {
  display: grid;
  grid-template-columns: 2fr 2fr;
}
</style>
