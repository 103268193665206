<template>
  <div class="report-bars__wrapper">
    <div class="report-bars-control__wrapper">
      <BarsControl
        :active-sort="activeSort"
        :disabled="loading"
        :options-for-sort="optionsForSort"
        @sort-change="sortChange"
      />
    </div>
    <div class="report-bars-main__wrapper">
      <div class="flex fullwidth gap-30 t-flex-wrap">
        <div
          v-show="showIncoming"
          class="report-bars__bar-wrapper"
        >
          <div>
            <RiskExposureHeader
              :amount="accSofAmount"
              header="Source of Funds"
              :loading="loading"
              :price="accSofPrice"
              :transactions="uniqueTransactionsIncoming"
            />
          </div>
          <gl-scoring-bar-chart
            :data-table="activeDataSourcesIncoming"
            :full="showFullBarsIncoming"
            :loading="loading"
            :per-page="barsPerPage"
            :two-line-view="isMobile"
          />
          <div
            v-if="showShowMoreButtonIncoming"
            class="flex fullwidth align-center justify-center mt-3"
          >
            <div
              class="gl-button__more-less"
              @click="toggleBarsPerPageIncoming"
            >
              {{ showFullBarsIncoming ? 'Less' : 'More' }}
            </div>
          </div>
        </div>
        <div
          v-show="showOutgoing"
          class="report-bars__bar-wrapper"
        >
          <div>
            <RiskExposureHeader
              :amount="accUofAmount"
              header="Use of Funds"
              :loading="loading"
              :price="accUofPrice"
              :transactions="uniqueTransactionsOutgoing"
            />
          </div>
          <gl-scoring-bar-chart
            :data-table="activeDataSourcesOutgoing"
            :full="showFullBarsOutgoing"
            :loading="loading"
            :per-page="barsPerPage"
            reverse
            :two-line-view="isMobile"
          />
        </div>
      </div>
      <div
        v-if="showShowMoreButtonGeneral"
        class="flex fullwidth align-center justify-center mt-3"
      >
        <div
          class="gl-button__more-less"
          @click="toggleBarsPerPage"
        >
          {{
            (showFullBarsIncoming && isDesktop) || showFullBarsOutgoing
              ? 'Less'
              : 'More'
          }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// Vuex
import { mapState, mapGetters } from 'vuex'
// Components
import BarsControl from '@/pages/report/components/bars/BarsControl'
// import GlScoringBarChart from '@/components/charts/gl-scoring-bar-chart.vue'
import GlScoringBarChart from '@/components/charts/gl-scoring-bar-chart-2'
import RiskExposureHeader from '@/pages/report/components/bars/RiskExposureHeader'

// Utils
import appConfig from '@/utils/appConfig'
import { formatterAmountValue } from '@/utils/report-data-formatter'
import { toComaSeparate } from '@/utils/formatNumber'
import { sortingObjects, sourcesSortOrderArray } from '@/utils/sorting'

// Mixins
import deviceWidthMixin from '@/assets/mixins/deviceWidthMixin'

export default {
  components: { BarsControl, GlScoringBarChart, RiskExposureHeader },
  mixins: [deviceWidthMixin],
  props: {
    loading: {
      type: Boolean,
      default: false,
    },
    pureSources: {
      type: Object,
      default: () => ({}),
    },
    allDataSource: {
      type: Object,
      default: () => ({}),
    },
    allDataSourceByOwner: {
      type: Object,
      default: () => ({}),
    },
    selectedToken: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      showFullBarsIncoming: false,
      showFullBarsOutgoing: false,
      barsPerPage: 10,
      activeSort: {
        text: 'Highest amount',
        value: 'amount',
        key: 'hamount',
        sortOrder: 'desc',
      },
      optionsForSort: [
        {
          text: 'Highest amount',
          value: 'amount',
          key: 'hamount',
          sortOrder: 'desc',
        },
        {
          text: 'Lowest amount',
          value: 'amount',
          key: 'lamount',
          sortOrder: 'asc',
        },
        {
          text: 'Highest risk',
          value: 'funds.score',
          key: 'hscore',
          sortOrder: 'desc',
        },
        {
          text: 'Lowest risk',
          value: 'funds.score',
          key: 'lscore',
          sortOrder: 'asc',
        },
      ],
    }
  },
  computed: {
    ...mapState('analytics', ['coinData']),
    ...mapGetters('directions', [
      'getActivePartBar',
      'getActiveTypeEntityItem',
      'getActiveExposurePart',
      'getExposurePartOptions',
    ]),
    appConfig() {
      return appConfig
    },
    activeDataSourcesIncoming() {
      if (this.getActivePartBar.value === 'type') {
        return (
          this.sortingObjects(this.allDataSource?.incoming, this.sortParams) ||
          []
        )
      } else if (this.getActivePartBar.value === 'entity') {
        return (
          this.sortingObjects(
            this.allDataSourceByOwner?.incoming,
            this.sortParams,
          ) || []
        )
      }
      return this.allDataSource
    },
    activeDataSourcesOutgoing() {
      if (this.getActivePartBar.value === 'type') {
        return (
          this.sortingObjects(this.allDataSource?.outgoing, this.sortParams) ||
          []
        )
      } else if (this.getActivePartBar.value === 'entity') {
        return (
          this.sortingObjects(
            this.allDataSourceByOwner?.outgoing,
            this.sortParams,
          ) || []
        )
      }
      return this.allDataSource
    },
    showShowMoreButtonIncoming() {
      return (
        this.activeDataSourcesIncoming.length > this.barsPerPage &&
        this.getActiveExposurePart.value === 'incoming' &&
        this.getActiveExposurePart.value !== 'full' &&
        !this.loading &&
        !Object.keys(this.getActiveTypeEntityItem).length
      )
    },
    showShowMoreButtonGeneral() {
      return (
        ((Number(this.activeDataSourcesIncoming.length) > this.barsPerPage &&
          this.showIncoming &&
          this.isDesktop) ||
          Number(this.activeDataSourcesOutgoing.length) > this.barsPerPage) &&
        this.showOutgoing &&
        !this.loading &&
        !Object.keys(this.getActiveTypeEntityItem).length
      )
    },
    showIncoming() {
      return (
        this.getActiveExposurePart.value === 'full' ||
        this.getActiveExposurePart.value === 'incoming'
      )
    },
    showOutgoing() {
      return (
        (this.getActiveExposurePart.value === 'full' ||
          this.getActiveExposurePart.value === 'outgoing') &&
        (Number(appConfig.VUE_APP_SCORE_REQUEST_DIRECTION) === 2 ||
          Number(appConfig.VUE_APP_SCORE_REQUEST_DIRECTION) === 1)
      )
    },
    uniqueTransactionsIncoming() {
      return this.getUniqueTxLengthFromSource(this.pureSources.incoming)
    },
    uniqueTransactionsOutgoing() {
      return this.getUniqueTxLengthFromSource(this.pureSources.outgoing)
    },
    accSofPrice() {
      return this.accumulateValues(this.activeDataSourcesIncoming, 'amountCur')
    },
    accUofPrice() {
      return this.accumulateValues(this.activeDataSourcesOutgoing, 'amountCur')
    },
    accSofAmount() {
      return this.accumulateValues(
        this.activeDataSourcesIncoming,
        'amount',
        this.formatterAmountValue,
      )
    },
    accUofAmount() {
      return this.accumulateValues(
        this.activeDataSourcesOutgoing,
        'amount',
        this.formatterAmountValue,
      )
    },
    sortParams() {
      return [
        { key: this.activeSort?.value, order: this.activeSort?.sortOrder },
        ...sourcesSortOrderArray,
      ]
    },
  },
  methods: {
    formatterAmountValue,
    toComaSeparate,
    sortingObjects,
    toggleBarsPerPage() {
      if (this.isDesktop) {
        this.showFullBarsIncoming = !this.showFullBarsIncoming
        this.showFullBarsOutgoing = !this.showFullBarsOutgoing
      } else {
        this.showFullBarsOutgoing = !this.showFullBarsOutgoing
      }
    },
    toggleBarsPerPageIncoming() {
      this.showFullBarsIncoming = !this.showFullBarsIncoming
    },
    sortChange(value) {
      this.activeSort = value
    },
    getUniqueTxLengthFromSource(sources) {
      const uniqueTX = [
        ...new Set(
          sources?.map(source => {
            return source.directTx
          }),
        ),
      ]
      return uniqueTX.length || 0
    },
    accumulateValues(dataSource, key, formatterValue = null) {
      const hasInvalidValue = dataSource.some(el => !el[key])
      const totalSum = dataSource.reduce((acc, el) => acc + (el[key] || 0), 0)
      if (hasInvalidValue) {
        return 0
      }
      return formatterValue
        ? this.toComaSeparate(
            formatterValue(
              totalSum,
              this.selectedToken.decimals ?? this.coinData.decimals,
              this.selectedToken.symbol ?? this.coinData.label,
            ),
          )
        : totalSum
    },
  },
}
</script>

<style>
.report-bars-control__wrapper {
  margin-bottom: 16px;
}
.report-bars__bar-wrapper {
  width: 100%;
  min-width: calc(50% - 15px);
}

.report-bars__wrapper .loader {
  width: calc(100%) !important;
}
</style>
