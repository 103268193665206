var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',[_c('div',{staticClass:"flex mb-4 flex-wrap gap-4 t-column"},[_c('div',{staticClass:"flex-2 fullwidth"},[_c(_vm.sectionsComponent,{tag:"component",staticClass:"mb-4",attrs:{"address":_vm.address,"address-data":_vm.addressData,"address-data-loading":_vm.addressDataLoading,"contract-data":_vm.contractData,"has-txs":_vm.hasTxs,"selected-token":_vm.selectedToken}})],1),_c('div',{staticClass:"flex-1 report-risk-score-wrapper"},[_c('Section',{scopedSlots:_vm._u([{key:"title",fn:function(){return [_vm._v(" Risk Score ")]},proxy:true},{key:"body",fn:function(){return [_c('div',{staticClass:"pa-3 m-pa-2"},[_c('GlProgressBarChart',{attrs:{"calculation-loading":_vm.calculationLoading,"has-funds-data":_vm.hasTxs,"total-funds":_vm.totalFunds}}),(_vm.addressRiskySources.incoming && _vm.addressRiskySources.incoming.length > 0)?_c('div',{staticClass:"mt-4 mb-4"},[_c('strong',[_vm._v(_vm._s(_vm.formatShare(_vm.percent)))]),_vm._v(" of funds comes from risky sources ")]):_c('div',{staticClass:"mt-4 mb-4"},[_vm._v(" No risky sources were found ")]),(
                  !_vm.calculationLoading && Object.keys(_vm.addressData).length > 0
                )?_c('div',{staticClass:"mb-5"},[(
                    _vm.addressIsOwnerByHightRisk ||
                      _vm.hasDirectlyMixing ||
                      _vm.percent * 100 > 0 ||
                      _vm.addressAreUnidentified ||
                      _vm.hasTagMoreRiskPoint
                  )?_c('p',{staticClass:"sidebar__analytics-label"},[_vm._v(" AML RISK DETECTED ")]):_vm._e(),(_vm.addressIsOwnerByHightRisk)?_c('StatusBlock',{staticClass:"mb-2 info-block__wrap",attrs:{"label":"Address is owned by a high risk entity"}}):_vm._e(),(_vm.hasDirectlyMixing)?_c('StatusBlock',{staticClass:"mb-2 info-block__wrap",attrs:{"label":"Address has directly participated in mixing"}}):_vm._e(),(_vm.percent * 100 > 0)?_c('StatusBlock',{staticClass:"mb-2 info-block__wrap",attrs:{"label":`Address received ${
                    _vm.sourceConfigsByDirection?.incoming?.hasOnlyOneSource ? '100%' : _vm.formatShare(_vm.percent)
                  } funds from risky sources`}}):_vm._e(),(_vm.addressAreUnidentified)?_c('StatusBlock',{staticClass:"mb-2 info-block__wrap",attrs:{"label":"More than 75% of sources for the address are unidentified"}}):_vm._e(),(_vm.hasTagMoreRiskPoint)?_c('StatusBlock',{staticClass:"mb-2 info-block__wrap",attrs:{"label":"Address has directly participated in high risk activities"}}):_vm._e()],1):_vm._e(),_c('RiskScoreDisclaimer'),_c('button',{staticClass:"gl-button gl-button--dark gl-button--padder gl-button--full mt-3",attrs:{"disabled":_vm.addressDataLoading},on:{"click":function($event){return _vm.explore(_vm.addressData.tx_hash)}}},[_vm._v(" Explore graph ")])],1)]},proxy:true}])})],1)]),_c('SectionBlock',{staticClass:"mb-4",scopedSlots:_vm._u([{key:"title",fn:function(){return [_vm._v(" Risk Exposure ")]},proxy:true},{key:"body",fn:function(){return [(_vm.appConfig.VUE_APP_SCORE_CHART_DONUT)?[_c('div',{staticClass:"flex mb-4 flex-wrap pl-2 pr-2 m-column gap-4"},[_c('div',{staticClass:"flex-1 m-fullwidth"},[_c('PieDataList',{attrs:{"data":_vm.allDataSource.incoming,"loading":_vm.calculationLoading,"title":"By Type","track-by-label":"funds.type","track-by-label-support":"funds.name"}})],1),_c('div',{staticClass:"flex-1 m-fullwidth"},[_c('PieDataList',{attrs:{"data":_vm.allDataSourceByOwner.incoming,"loading":_vm.calculationLoading,"title":"By Owner","track-by-label":"owner"}})],1)])]:[_c('ReportBarsWrapper',{attrs:{"all-data-source":_vm.allDataSource,"all-data-source-by-owner":_vm.allDataSourceByOwner,"loading":_vm.calculationLoading,"pure-sources":_vm.pureSources,"selected-token":_vm.selectedToken}})]]},proxy:true}])}),(_vm.appConfig.VUE_APP_SCORE_CHART_DONUT)?_c('div',{staticClass:"report-block-wrap"},[(_vm.calculationLoading)?_c('div',{staticClass:"ma-3 bold fs-18"},[_vm._v(" Loading... ")]):_c('div',[(!_vm.addressSwapSourcesResult.length && !_vm.addressRiskySourcesResult.length &&
            !_vm.addressUnknownSourcesResult.length && !_vm.addressKnownSourcesResult.length)?_c('div',{staticClass:"ma-3 bold fs-18"},[(_vm.getActiveExposurePart.value == 'incoming')?_c('div',[_vm._v(" "+_vm._s(Object.keys(_vm.getActiveTypeEntityItem).length ? 'There is no sources information available with the chosen type or owner. Please select a different option to view the sources of funds.' : 'There is no sources information available.')+" ")]):_vm._e(),(_vm.getActiveExposurePart.value == 'outgoing')?_c('div',[_vm._v(" "+_vm._s(Object.keys(_vm.getActiveTypeEntityItem).length ? 'There is no destination information available with the chosen type or owner. Please select a different option to view the destinations of funds.' : 'There is no destination information available.')+" ")]):_vm._e()]):_vm._e(),(_vm.addressSwapSourcesResult.length > 0)?_c('SwapTable',{staticClass:"mb-5",attrs:{"data":_vm.addressSwapSourcesResult,"has-only-one-source":_vm.sourceConfigsByDirection[_vm.activeExposurePart.value]?.hasOnlyOneSource}}):_vm._e(),(_vm.addressRiskySourcesResult.length > 0)?_c('AddressRiskyTable',{staticClass:"mb-5",attrs:{"data":_vm.addressRiskySourcesResult,"has-only-one-source":_vm.sourceConfigsByDirection[_vm.activeExposurePart.value]?.hasOnlyOneSource}}):_vm._e(),(_vm.addressUnknownSourcesResult.length > 0)?_c('AddressUnknownTable',{staticClass:"mb-5",attrs:{"data":_vm.addressUnknownSourcesResult,"has-only-one-source":_vm.sourceConfigsByDirection[_vm.activeExposurePart.value]?.hasOnlyOneSource}}):_vm._e(),(_vm.addressKnownSourcesResult.length > 0)?_c('AddressKnownTable',{attrs:{"data":_vm.addressKnownSourcesResult,"has-only-one-source":_vm.sourceConfigsByDirection[_vm.activeExposurePart.value]?.hasOnlyOneSource}}):_vm._e()],1)]):_c('ReportTablesWrapper',{attrs:{"currencies-by-direction":_vm.currenciesByDirection,"loading":_vm.calculationLoading,"pure-sources":_vm.pureSources,"report-type":"address"}})],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }