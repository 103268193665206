<template>
  <GlPageWrap
    class="report-page__wrapper relative"
    hide-title-on-mobile
    title="Risk Report"
  >
    <div class="flex m-pa-2 m-fixed-bottom-wrapper">
      <GlButton
        v-if="hasReportData && !isEthAddressReport && !isEthTxReport"
        class="save-csv-button height-28 m-static m-flex-1 mr-3"
        dark
        :disabled="calcLoading || addressDataLoading || txDataLoading"
        title="Export to csv"
        @click="exportReportToCsv"
      />
      <GlButton
        v-if="hasReportData && enableGeneratePdf"
        class="save-button height-28 m-flex-1"
        dark
        :loading="pdfLoading"
        title="Export to pdf"
        @click="exportToPdf"
      />
    </div>

    <div class="report__wrapper">
      <div
        class="report-search-control__wrapper"
        :class="{'gap-3 m-gap-2': coinData.isNeedTokensCall && (tokensList.length || txInfo.contractsListData?.length) }"
      >
        <div class="report__search-bar toolbar__wrap-coin-select">
          <GlCoinSelect
            class="m-mb-3"
            dark
            :slim="isMobile ? true : false"
          />
          <div class="flex fullwidth m-column m-align-center">
            <gl-search-box
              v-model="search"
              button-text="Search"
              class="search-box fullwidth mr-4 m-mb-3 m-mr-0"
              dark-clear
              :disabled="!search"
              grey
              :independent="isMobile || isTablet ? true : false"
              is-search
              :loading="calcLoading || addressDataLoading || txDataLoading"
              placeholder="Enter the address or tx hash"
              tagging
              @search="searchData"
            />
            <div class="flex flex-end m-fullwidth">
              <gl-requests-counter />
            </div>
          </div>
        </div>
        <transition name="slide">
          <div
            v-show="coinData.isNeedTokensCall && !tokensLoading"
          >
            <ChooseTokenList
              :address-hash="isEthAddressReport ? searchValue : txInfo.tx_hash"
              :disable="calcLoading || addressDataLoading"
              :request-type="isEthAddressReport ? 'address' : 'tx'"
              :selected-token="selectedToken"
              :tokens-list="isEthAddressReport ? tokensList : txInfo.contractsListData"
              @set-token="getReportByToken"
            />
          </div>
        </transition>
      </div>
      <div>
        <AddressReport
          v-if="isAddressReport"
          :address-data="addressInfo"
          :address-data-loading="addressDataLoading"
          :address-known-sources="addressKnownSources"
          :address-risky-sources="addressRiskySources"
          :address-unknown-sources="addressUnknownSources"
          :all-data-source="allDataSource"
          :all-data-source-by-owner="allDataSourceByOwner"
          :calculation-loading="calcLoading"
          :currencies-by-direction="currenciesByDirection"
          :percent="sourcesRiskPercent"
          :pure-sources="pureSources"
          :source-configs-by-direction="sourceConfigsByDirection"
          :total-funds="addressTotalFunds"
        />
        <TxReport
          v-else-if="isTxReport"
          :all-data-source="allDataSource"
          :all-data-source-by-owner="allDataSourceByOwner"
          :calculation-loading="calcLoading"
          :currencies-by-direction="currenciesByDirection"
          :messages="messages"
          :percent="txRiskPercent"
          :pure-sources="pureSources"
          :source-configs-by-direction="sourceConfigsByDirection"
          :total-funds="txTotalFunds"
          :tx-data="txInfo"
          :tx-data-loading="txDataLoading"
          :tx-known-sources="txKnownSources"
          :tx-risky-sources="txRiskySources"
          :tx-unknown-sources="txUnknownSources"
        />
        <EthTxReport
          v-else-if="isEthTxReport"
          :all-data-source="allDataSource"
          :all-data-source-by-owner="allDataSourceByOwner"
          :calculation-loading="calcLoading"
          :currencies-by-direction="currenciesByDirection"
          :messages="messages"
          :percent="txRiskPercent"
          :pure-sources="pureSources"
          :selected-token="selectedToken"
          :source-configs-by-direction="sourceConfigsByDirection"
          :swap-sources="swapSources"
          :symbol="symbol"
          :total-amount="totalAmount"
          :total-funds="txTotalFunds"
          :tx-data="txInfo"
          :tx-data-loading="txDataLoading"
          :tx-known-sources="txKnownSources"
          :tx-risky-sources="txRiskySources"
          :tx-unknown-sources="txUnknownSources"
        />
        <EthAddressReport
          v-else-if="isEthAddressReport"
          :address="searchValue"
          :address-data="addressInfo"
          :address-data-loading="addressDataLoading"
          :address-known-sources="addressKnownSources"
          :address-risky-sources="addressRiskySources"
          :address-unknown-sources="addressUnknownSources"
          :all-data-source="allDataSource"
          :all-data-source-by-owner="allDataSourceByOwner"
          :calculation-loading="calcLoading"
          :contract-data="contractData"
          :currencies-by-direction="currenciesByDirection"
          :percent="sourcesRiskPercent"
          :pure-sources="pureSources"
          :selected-token="selectedToken"
          :source-configs-by-direction="sourceConfigsByDirection"
          :swap-sources="swapSources"
          :tokens-list="tokensList"
          :tokens-loading="tokensLoading"
          :total-funds="addressTotalFunds"
          @set-token="getReportByToken"
        />
        <div
          v-else
          class="cyto-empty"
        >
          Type address or transaction hash in the search bar above to get sources of funds report.
        </div>
      </div>
    </div>
  </GlPageWrap>
</template>

<script>
// Vuex
import { mapActions, mapMutations, mapState, mapGetters } from "vuex";
//Components
import GlButton from "@/components/gl-button";
import GlSearchBox from '@/components/gl-search-box'
import GlCoinSelect from "@/components/gl-coin-select";
import TxReport from "@/pages/report/components/TxReport";
import GlPageWrap from "@/components/layout/gl-page-wrap";
import EthTxReport from "@/pages/report/components/EthTxReport";
import AddressReport from "@/pages/report/components/AddressReport";
import EthAddressReport from "@/pages/report/components/EthAddressReport";
import ChooseTokenList from '@/pages/report/components/ChooseTokenList.vue'
// Utils
import axios from 'axios'
import config from "@/utils/appConfig";
import { csvExportReport} from '@/utils/export-data'
import { findFiatByKey } from "@/utils/format-by-price";
import { formatBtcAmount } from "@/utils/format-btc-amount";
import { formatShare, formatter } from "@/utils/sourcesFormatter";
import { sortingObjects, sourcesSortOrderArray } from "@/utils/sorting";
import { calcSourcesRiskPercent, formatterAmountValue, calcTotalFunds } from '@/utils/report-data-formatter'
import { findColorByTypeScore, tokensSorting } from "@/utils/cytoskape-ui-rules";
import { featureAccess } from "@/utils/accesses";
import { toComaSeparate } from '@/utils/formatNumber';
import { spamMessagesCombine } from '@/utils/text-formatter';
import { errorsMessageListForRestrictPDFDownload } from '@/utils/errors';
//mixins
import deviceWidthMixin from '@/assets/mixins/deviceWidthMixin'
import { AlertsMixin } from "@/assets/mixins/alertsMixin";
import momentTimezone from 'moment-timezone'
import GlRequestsCounter from '@/components/gl-requests-counter.vue'
import appConfig from '@/utils/appConfig'
// import appConfig from '@/utils/appConfig'

export default {
  components: {
    GlRequestsCounter,
    TxReport,
    GlPageWrap,
    EthTxReport,
    GlSearchBox,
    GlCoinSelect,
    AddressReport,
    EthAddressReport,
    GlButton,
    ChooseTokenList
  },
  mixins: [deviceWidthMixin, AlertsMixin],
  data() {
    return {
      search: '',
      addressInfo: {},
      txInfo: {},
      isEthTxReport: false,
      isAddressReport: false,
      isEthAddressReport: false,
      isTxReport: false,
      addressDataLoading: false,
      txDataLoading: false,
      calcLoading: false,
      tokensLoading: false,
      pdfLoading: false,
      swapSources: {},
      allDataSource: {},
      allDataSourceByOwner: {},
      addressRiskySources: {},
      addressUnknownSources: {},
      addressKnownSources: {},
      txRiskySources: {},
      txUnknownSources: {},
      txKnownSources: {},
      pureSources: {},
      currenciesByDirection: {},
      sourceConfigsByDirection: {},
      messages: [],
      tokensList: [],
      contractData: {},
      sourcesRiskPercent: 0,
      txRiskPercent: 0,
      addressTotalFunds: '',
      txTotalFunds: '',
      totalAmount: 0,
      symbol: '',
      selectedToken: {
        symbol: 'ETH',
        address: ""
      },
      searchValue: '',
      currentScoreErrorMessage: {},
    }
  },
  computed: {
    ...mapState('user', ['userData']),
    ...mapState('analytics', ['coinType', 'coinData', 'currencyList']),
    ...mapGetters('directions', ['getActiveExposurePart', 'getExposurePartOptions', 'getActivePartBar']),
    enableGeneratePdf() {
      const resolveIncludedErrors = (object, array) => {
        const values = Object.values(object);
        return !!values.length && values.every(value => array.includes(value));
      };
      return config.VUE_APP_PDF_GENERATOR && !resolveIncludedErrors(this.currentScoreErrorMessage, errorsMessageListForRestrictPDFDownload)
    },
    hasReportData() {
      return Object.keys(this.addressInfo).length !== 0 || Object.keys(this.txInfo).length !== 0 || this.allDataSource?.incoming?.length || this.allDataSource?.outgoing?.length
    },
    addressReport() {
      return this.isAddressReport || this.isEthAddressReport
    },
  },
  watch: {
    isDesktop: {
      handler(val) {
        this.setFieldInExposurePart('full', 'hide', Boolean(!val))
        this.setFieldInExposurePart('full', 'disabled', Boolean(!val))
        this.changeToAvailableExposurePart()
      },
      immediate: true
    },
  },
  mounted() {
    const { query } = this.$route

    if (query.type) {
      this.SET_COIN_TYPE(query.type)
      const coin = this.currencyList.find(curr => curr.key === query.type)
      this.SET_COIN_DATA(coin)
      this.selectedToken.symbol = this.coinData.label
    }

    this.selectedToken.address = this.$route.query.token || ''

    if (query.address) {
      this.search = query.address
      this.searchData(query.address)
      return
    }

    if (query.tx) {
      this.search = query.tx
      this.searchData(query.tx)
    }
  },
  methods: {
    ...mapActions({
      getTxEthData: 'analytics/getTxEthData',
      getTxAMLInfo: 'analytics/getTxAMLInfo',
      getAddressData: 'analytics/getAddressData',
      getTxRiskReport: 'analytics/getTxRiskReport',
      getAddressTokens: 'analytics/getAddressTokens',
      getAddressBalance: 'analytics/getAddressBalance',
      getAddressMetrics: 'analytics/getAddressMetrics',
      getEthAddressInfo: 'analytics/getEthAddressInfo',
      getTransactionInfo: 'analytics/getTransactionInfo',
      getTransactionTokens: 'analytics/getTransactionTokens',
      getAddressRiskReport: 'analytics/getAddressRiskReport',
      getAddressUOF: 'analytics/getAddressUOF',
      getTxUOF: 'analytics/getTxUOF',
    }),
    ...mapMutations('sidebar', ['SET_SHRUNK']),
    ...mapActions('entity', ['getEntityList']),
    ...mapMutations('analytics', ['SET_COIN_TYPE', 'SET_COIN_DATA']),
    ...mapActions('analytics', ['getAddressTokens', 'getTxEthScore', 'getAddressEthScore', 'getEthAddressInfo', 'getAddressBalance', 'getAddressMetrics', 'getTransactionTokens']),
    ...mapMutations('directions', ['SET_ACTIVE_EXPOSURE_PART', 'SET_ACTIVE_PART_BAR', 'SET_EXPOSURE_PART_OPTIONS']),
    formatter,
    formatShare,
    featureAccess,
    formatBtcAmount,
    tokensSorting,
    csvExportReport,
    findColorByTypeScore,
    formatterAmountValue,
    calcSourcesRiskPercent,
    findFiatByKey,
    toComaSeparate,
    calcTotalFunds,
    spamMessagesCombine,
    getCountryAndCity() {
      const timezone = momentTimezone.tz.guess();
      const zone = momentTimezone.tz.zone(timezone);
      return zone ? { timeZoneName: zone.name } : { error: "Unable to determine local time zone" };
    },
    formatterDepthSortValue(value) {
      if (typeof value === 'number') {
        return value
      }

      if (value && value.minimum && value.maximum) {
        return value.minimum
      } else {
        return null
      }
    },
    exportToPdf() {
      this.pdfLoading = true

      const { timeZoneName } = this.getCountryAndCity()

      axios({
        url: `${process.env.VUE_APP_DOCUMENT_GENERATOR_URL}/report/${
          this.addressReport ? 'address' : 'tx'
        }/${this.search}?userId=${this.userData.id}&currencyKey=${
          this.coinData.key
        }&tokenAddress=${this.selectedToken.address}&preferredCurrency=${
          findFiatByKey(this.userData.preferredCurrency).label
        }&timezone=${timeZoneName}&activePartBarValue=${this.getActivePartBar.value
        }&direction=${config.VUE_APP_SCORE_REQUEST_DIRECTION}`,
        method: 'GET',
        responseType: 'blob',
        headers: { ['x-api-key']: this.userData.apikey },
      }).then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', `report_${this.search}.pdf`);
        document.body.appendChild(link);
        link.click();
      }).finally(() => this.pdfLoading = false);
    },
    getReportByToken(token) {

      if (this.isEthTxReport) {
        token = this.selectedToken.address === token.address ? {} : token
      }

      this.selectedToken = token

      const selectedTokenIndex = this.tokensList.findIndex(el => el.address === this.selectedToken.address)
      this.moveElementToTop(this.tokensList, selectedTokenIndex)
      // const allTokenIndex = this.tokensList.findIndex(el => el.address === 'all')
      // this.moveElementToTop(this.tokensList, allTokenIndex)

      this.searchData(this.search, false)
    },
    moveElementToTop(array, index) {
      if (index > array.length - 1 || index < 0) {
        // Index out of bounds
        return;
      }
      const element = array.splice(index, 1)[0];
      array.unshift(element);
    },
    loadMetrics(value) {
      this.loadingMetrics = true

      const sendData = {
        address: value,
        contract: this.selectedToken.address ? this.selectedToken.address : undefined,
        type: this.selectedToken.address ? 'tokens' : 'main'
      }

      this.getAddressMetrics(sendData).then(({ data: { totalTxs } }) => {
        this.addressInfo = {
          ...this.addressInfo,
          txCount: totalTxs.total,
          txInputsCount: totalTxs.inputs,
          txOutputsCount: totalTxs.outputs,
        }
      }).catch(() => this.addressInfo.txCount = 0).finally(() => {
        this.loadingMetrics = false
      })
    },
    async searchData(value, isNeedTokensCall = true) {
      this.SET_SHRUNK(true)

      if (!this.selectedToken.address) {
        this.selectedToken = {
          symbol: this.coinData.label,
          address: ""
        }
      }

      if (this.searchValue && this.searchValue !== value) {
        this.selectedToken = {
          symbol: this.coinData.label,
          address: ""
        }
      }

      this.searchValue = value

      this.clearData()

      await this.SET_COIN_TYPE(this.coinType)

      if (this.coinData.family === 'eth' && new RegExp(this.coinData.addressRegex).test(value) && !new RegExp(this.coinData.txRegex).test(value)) {
        this.isEthAddressReport = true
        this.addressDataLoading = true
        this.calcLoading = true
        this.addressInfo = {}
        this.contractData = {}

        await this.getEthAddressInfo(value).then(({ data, success }) => {
          if (success) {
            this.addressInfo = {
              ...this.addressInfo,
              ...data,
              meta: data.tags ? data.tags.map((item) => ({
                ...item,
                metadata: data.meta && data.meta.find(el => el.tagId === item._id)
                  ? data.meta.find(el => el.tagId === item._id).metadata
                  : ''
              })) : [],
              assumedMeta: this.formattingScoringList(data) || []
            }

              this.contractData = {
                ...data,
                type: data.addressType
              }
              try {
                this.gettingEntityByOwner(data?.owner)
              } catch (e) {console.log(e)}

          }
        }).catch(() => {
          this.addressDataLoading = false
        })

        if (isNeedTokensCall) {
          await this.setAddressTokens(value)
        }

        this.getAddressBalance({
          address: value,
          contract: this.selectedToken.address ? this.selectedToken.address : undefined })
        .then(({ data }) => {
          this.addressInfo.balance = data?.balance?.amount || 0
          this.addressInfo.price = data?.balance?.price || 0
          this.addressInfo.priceTimestamp = data?.balance?.priceTimestamp || null
          this.addressInfo.symbol = data?.balance?.symbol
        }).finally(() => {
          this.addressDataLoading = false
        })

        await this.getAddressUOF({ address: this.search, token: this.selectedToken.address, tokenTicker: this.selectedToken.symbol } ).then(({ data, success }) => {
          if (!success && data.message) {
            this.$toasted.global.error({ message: data.message })
            this.$set(this.currentScoreErrorMessage, 'global', data.message)
          }

          const { sof, uof }  = data

          const sofTotalFunds = sof?.data?.totalFunds ?? null;
          const uofTotalFunds = uof?.data?.totalFunds ?? null;

          this.addressTotalFunds = (sofTotalFunds === null && uofTotalFunds === null)
            ? null
            : (!appConfig.VUE_APP_MIN_MAX_TOTAL_FUNDS ? sofTotalFunds : Math.max(sofTotalFunds, uofTotalFunds));

          if (sof) {
            const { data: { sources, alerts, currencies, message, meta, isMonitoring, totalFunds, selfFunding }, success } = sof
            this.setAddressSources( { sources, alerts, currencies, value, meta, message, success, isMonitoring, totalFunds, selfFunding }, 'incoming')
          }

          if (uof) {
            const { data: { sources, alerts, currencies, message, meta, isMonitoring, selfFunding }, success } = uof
            this.setAddressSources( { sources, alerts, currencies, value, message, meta, success, isMonitoring, selfFunding }, 'outgoing')
          }
        }).catch(() => {
          this.calcLoading = false
        }).finally(() => {
          this.calcLoading = false
          // this.addressTotalFunds = this.calcTotalFunds(this.allDataSource.incoming, this.allDataSource.outgoing)
          this.sourcesRiskPercent = this.calcSourcesRiskPercent(this.allDataSource.incoming)
          this.setFieldInExposurePart('incoming', 'disabled', Boolean(!this.allDataSource?.incoming?.length))
          this.setFieldInExposurePart('outgoing', 'disabled', Boolean(!this.allDataSource?.outgoing?.length))
          this.changeToAvailableExposurePart()
          this.loadMetrics(value)
        })

        if (!this.selectedToken.address && !this.allDataSource?.incoming?.length && !this.allDataSource?.outgoing?.length && this.tokensList.length > 0 && this.tokensList.find(contract => contract.address)) {
          this.selectedToken = this.tokensList.find(contract => contract.address)
          await this.searchData(this.search)
        }

        if (this.$route.name === 'report') {
          await this.$router.push({ name: 'report', query: { type: this.coinType, address: value, token: this.selectedToken.address } })
        }

        return
      }

      if (this.$can('use', this.coinData.family)  && this.coinData.family === 'eth' && new RegExp(this.coinData.txRegex).test(value)) {
        this.isEthTxReport = true
        this.txDataLoading = true
        this.calcLoading = true

        this.txInfo = {}
        this.totalAmount = {}

        if (this.coinType && !this.selectedToken.address) this.selectedToken.symbol = this.coinData.label

        const a = await this.getTransactionTokens({ tx: this.search }).then(({ data, success }) => {
          if (!success) this.calcLoading = false
          if (data.message) {
            this.txInfo = {}
            this.totalAmount = {}
            this.$toasted.global.error({message: `${data.message}`})
          }
          const formattedTokensList = data.map(token => {
            return {
              ...token,
              name: token.symbol
            }
          })

          this.txInfo.contractsListData = tokensSorting(formattedTokensList)
          // this.txInfo.contractsListData.unshift({ address: '', symbol: this.coinData.label, icon: getNativeCoinIcon(this.coinData.key) })

          if (this.txInfo.contractsListData.find(el => el.address === this.selectedToken.address)) {
            this.selectedToken = {
              ...this.txInfo.contractsListData.find(el => el.address === this.selectedToken.address),
              decimals: this.selectedToken.address ? this.txInfo.contractsListData.find(el => el.address === this.selectedToken.address).decimals || 0 : this.coinData.decimals
            }
          } else {
            if (!this.selectedToken.address) {
              this.selectedToken = {
                ...this.selectedToken,
                decimals: this.selectedToken.decimals ?? this.coinData.decimals ?? 0
              }
            }
          }
        }).catch(() => {
          this.calcLoading = false
        })

        const b = await this.getTxEthData({ tx: value }).then(({ data, success }) => {

          if (!success) this.calcLoading = false

          if (data.message) {
            this.txInfo = {}
            this.totalAmount = {}
            this.$toasted.global.error({message: `${data.message}`})
          }
          const localTxData = data?.txs[0] || {}

          this.txInfo = {
            ...this.txInfo,
            ...localTxData,
            tx_hash: this.search,
          }
        }).finally(() => {
          this.txDataLoading = false
        })

        const c = this.getTxUOF({ tx_hash: this.search, token: this.selectedToken.address, tokenTicker: this.selectedToken.symbol })
          .then(({ data, success}) => {
            if (success) {
              const { sof, uof }  = data

              const sofTotalFunds = sof?.data?.totalFunds ?? null;
              const uofTotalFunds = uof?.data?.totalFunds ?? null;

              this.txTotalFunds = (sofTotalFunds === null && uofTotalFunds === null)
                ? null
                : (!appConfig.VUE_APP_MIN_MAX_TOTAL_FUNDS ? sofTotalFunds : Math.max(sofTotalFunds, uofTotalFunds));

              if (sof.success) {
                const { data: { sources, totalAmount, amountCur, amountCurTimestamp, symbol, message, currencies, meta, totalFunds } } = sof
                this.setTxSources({ sources, totalAmount, amountCur, amountCurTimestamp, symbol, message, currencies, success, meta, totalFunds }, 'incoming')
              } else {
                this.$set(this.currentScoreErrorMessage, 'incoming', data?.sof?.data?.message)
              }

              if (uof.success) {
                const { data: { sources, totalAmount, amountCur, amountCurTimestamp, symbol, message, currencies, meta } } = uof
                this.setTxSources({ sources, totalAmount, amountCur, amountCurTimestamp, symbol, message, currencies, success, meta }, 'outgoing')
              } else {
                this.$set(this.currentScoreErrorMessage, 'outgoing', data?.sof?.data?.message)
              }
            } else {
              this.$set(this.currentScoreErrorMessage, 'global', data?.sof?.data?.message)
            }
          }).catch(() => {
            this.calcLoading = false
          }).finally(() => {
            this.calcLoading = false
            this.txRiskPercent = this.calcSourcesRiskPercent(this.allDataSource.incoming)
          this.setFieldInExposurePart('incoming', 'disabled', Boolean(!this.allDataSource?.incoming?.length))
          this.setFieldInExposurePart('outgoing', 'disabled', Boolean(!this.allDataSource?.outgoing?.length))
            this.changeToAvailableExposurePart()
          })

        await Promise.allSettled([a, b, c]).finally(() => {
          this.calcLoading = false
        })

        if (!this.selectedToken.address && !this.allDataSource?.incoming?.length && !this.allDataSource?.outgoing?.length && this.txInfo.contractsListData.length && this.txInfo.contractsListData.find(contract => contract.address)) {
          this.selectedToken = this.txInfo.contractsListData.find(contract => contract.address)
          await this.searchData(this.search)
        }
        await this.$router.push({ name: 'report', query: { tx: value, type: this.coinType, token: this.selectedToken.address } })
        return
      }

      if (this.$can('use', this.coinData.family) && new RegExp(this.coinData.txRegex).test(value)) {
        this.isTxReport = true
        this.txDataLoading = true
        this.calcLoading = true

        const a = this.getTransactionInfo({ txHash: value }).then(({ data }) => {
          if (data.message) {
            this.$toasted.global.error({message: `${data.message}`})
          }
          this.txInfo = {
            ...data,
            formattedTotalAmount: this.toComaSeparate(String(this.formatBtcAmount(data.inputsAmount, true))) || '0'
          }
          this.txDataLoading = false
        })
        const b = this.getTxAMLInfo({ tx_hash: value })
          .then(({ data }) => {
            this.messages = data.messages
          }).catch(({response: {data}}) => {
            if (data.statusCode && data.statusCode !== 500) {
              this.$toasted.global.error({message: `${data.message}`})
            }
          })

        const c = this.getTxUOF({ tx_hash: value })
          .then(({ data, success }) => {
            this.$set(this.currentScoreErrorMessage, 'global', data.message)

            if (success) {
              const { sof, uof }  = data

              const sofTotalFunds = sof?.data?.totalFunds ?? null;
              const uofTotalFunds = uof?.data?.totalFunds ?? null;

              this.txTotalFunds = (sofTotalFunds === null && uofTotalFunds === null)
                ? null
                : (!appConfig.VUE_APP_MIN_MAX_TOTAL_FUNDS ? sofTotalFunds : Math.max(sofTotalFunds, uofTotalFunds));

              if (sof.success) {
                const { data: { sources, meta, message, totalFunds }, success } = sof
                this.setTxSources({ sources, success, meta, message, totalFunds }, 'incoming')
              }

              if (uof.success) {
                const { data: { sources, meta, message }, success } = uof
                this.setTxSources({ sources, success, meta, message }, 'outgoing')
              }
            }
          }).catch(() => {
            this.calcLoading = false
          }).finally(() => {
            this.setFieldInExposurePart('incoming', 'disabled', Boolean(!this.allDataSource?.incoming?.length))
            this.setFieldInExposurePart('outgoing', 'disabled', Boolean(!this.allDataSource?.outgoing?.length))
            this.changeToAvailableExposurePart()
          })

        await Promise.allSettled([a, b, c]).catch(() => { this.calcLoading = false }).finally(() => {
          this.calcLoading = false
          this.txRiskPercent = this.calcSourcesRiskPercent(this.allDataSource.incoming)
        })

        await this.$router.push({ name: 'report', query: { tx: value, type: this.coinType } })
        return
      }

      if (this.$can('use', this.coinData.family) && new RegExp(this.coinData.addressRegex).test(value) ) {
        this.isAddressReport = true
        this.addressDataLoading = true
        this.calcLoading = true
        this.getAddressData({ address: value }).then(({data, success}) => {
          if (!success) {
            this.$toasted.global.error({message: `${data.message}`})
            return
          }
          this.addressInfo = {
            ...data,
            meta: data.tags ? data.tags.map((item) => ({
              ...item,
              metadata: data.meta && data.meta.find(el => el.tagId === item._id)
                ? data.meta.find(el => el.tagId === item._id).metadata
                : ''
            })) : [],
            assumedMeta: this.formattingScoringList(data) || [],
            formattedAmountSent: this.toComaSeparate(String(this.formatterAmountValue(data.amountSent, this.selectedToken.decimals ?? this.coinData.decimals, this.selectedToken.symbol ?? this.coinData.label))),
            formattedAmountReceived: this.toComaSeparate(String(this.formatterAmountValue(data.amountReceived, this.selectedToken.decimals ?? this.coinData.decimals, this.selectedToken.symbol ?? this.coinData.label))),
            formattedBalance: this.toComaSeparate(String(this.formatterAmountValue(data.balance, this.selectedToken.decimals ?? this.coinData.decimals, this.selectedToken.symbol ?? this.coinData.label)))
          }
          try {
            this.gettingEntityByOwner(data?.owner)
            this.gettingEntityByOwner(data?.clusterData?.owner, false)
          } catch (e) {
            console.log(e);
          }
        })
          .finally(() => {
            this.addressDataLoading = false
          })

        await this.getAddressUOF({ address: value }).then(({ data, success }) => {
          if (!success && data.message) {
            this.$toasted.global.error({ message: data.message })
            this.$set(this.currentScoreErrorMessage, 'global', data.message)
          }

          const { sof, uof }  = data

          const sofTotalFunds = sof?.data?.totalFunds ?? null;
          const uofTotalFunds = uof?.data?.totalFunds ?? null;

          this.addressTotalFunds = (sofTotalFunds === null && uofTotalFunds === null)
            ? null
            : (!appConfig.VUE_APP_MIN_MAX_TOTAL_FUNDS ? sofTotalFunds : Math.max(sofTotalFunds, uofTotalFunds));

          if (sof) {
            const { data: { sources, alerts, currencies, message, meta, isMonitoring, totalFunds }, success } = sof
            this.setAddressSources( {sources, alerts, currencies, value, message, meta, success, isMonitoring, totalFunds }, 'incoming')
          }

          if (uof) {
            const { data: { sources, alerts, currencies, message, meta, isMonitoring }, success } = uof
            this.setAddressSources( {sources, alerts, currencies, value, message, meta, success, isMonitoring }, 'outgoing')
          }
        }).catch(() => {
          this.calcLoading = false
        }).finally(() => {
          this.calcLoading = false
          this.sourcesRiskPercent = this.calcSourcesRiskPercent(this.allDataSource.incoming)
          this.setFieldInExposurePart('incoming', 'disabled', Boolean(!this.allDataSource?.incoming?.length))
          this.setFieldInExposurePart('outgoing', 'disabled', Boolean(!this.allDataSource?.outgoing?.length))
          this.changeToAvailableExposurePart()
        })

        await this.$router.replace({ name: 'report', query: { address: value, type: this.coinType } }).catch((err) => err)
        return
      }
      this.$toasted.global.error({ message: 'Search value is not valid'})
    },
    swapDataFormatter(currencies, activeExposurePart) {
      if (!this.swapSources[activeExposurePart]) return
      this.swapSources[activeExposurePart] = this.swapSources[activeExposurePart].map(t => {
        const currencyInputObject = t?.inputAmounts?.find(item => item?.currency !== this.selectedToken.address) || t?.inputAmounts[0];
        const currencyOutputObject = t?.outputAmounts?.find(item => item.currency === this.selectedToken.address) || t?.outputAmounts[0]
        const currencyInput = currencyInputObject && currencies[currencyInputObject.currency]
          ? {
            ...currencies[currencyInputObject.currency],
            amount: currencyInputObject.amount,
            amountCur: currencyInputObject.amountCur,
            amountCurTimestamp: currencyInputObject.amountCurTimestamp
          }
          : {...currencyInputObject} || null
        const currencyOutput = currencyOutputObject && currencyOutputObject.currency && currencies[currencyOutputObject.currency]
          ? {
            ...currencies[currencyOutputObject.currency],
            amount: currencyOutputObject.amount,
            amountCur: currencyOutputObject.amountCur,
            amountCurTimestamp: currencyOutputObject.amountCurTimestamp
          }
          : { ...currencyOutputObject } || null
        const currencyMain = t && t.currency && currencies[t.currency]
          ? {
            ...currencies[t.currency],
            amount: t.amount,
            amountCur: t.amountCur,
            amountCurTimestamp: t.amountCurTimestamp
          }
          : { ...t } || null
        return {
          ...t,
          swapData: {
            amount: formatBtcAmount(currencyMain?.amount / Math.pow(10, currencyMain.decimals ?? this.coinData.decimals ?? 0), true, this.coinData.family, currencyMain.currency === '' ? 'UNDECODED TOKENS' : currencyMain.currency || null) || '',
            amountCur: currencyMain?.amountCur,
            amountCurTimestamp: currencyMain?.amountCurTimestamp,
            from: {
              amountText: currencyInput ? formatBtcAmount(currencyInput.amount / Math.pow(10, currencyInput.decimals ?? this.coinData.decimals ?? 0), true, this.coinData.family, currencyInput.currency === '' ? 'UNDECODED TOKENS' : currencyInput.currency) : '',
              symbol: currencyInput.currency,
              amountCur: currencyInput?.amountCur,
              amountCurTimestamp: currencyInput?.amountCurTimestamp
            },
            to: {
              amountText: currencyOutput ? formatBtcAmount(currencyOutput.amount / Math.pow(10, currencyOutput.decimals ?? this.coinData.decimals ?? 0), true, this.coinData.family, currencyOutput.currency === '' ? 'UNDECODED TOKENS' : currencyOutput.currency) : '',
              symbol: currencyOutput.currency,
              amountCur: currencyOutput?.amountCur,
              amountCurTimestamp: currencyOutput?.amountCurTimestamp,
            }
          }
        }
      })
    },
    formattingScoringList(data) {
      let SCORING_LIST = []

      if (data.tags) {
        SCORING_LIST = [...SCORING_LIST, ...data.tags]
      }

      if (data.clusterData && data.clusterData.tags) {
        SCORING_LIST = [...SCORING_LIST, ...data.clusterData.tags]
      }

      if (data.type) {
        SCORING_LIST = [...SCORING_LIST, data.type]
      }

      if (data.clusterData && data.clusterData.type) {
        SCORING_LIST = [...SCORING_LIST, data.clusterData.type]
      }

      SCORING_LIST = SCORING_LIST.filter((v,i,a)=>a.findIndex(t=>(t.name===v.name))===i)

      SCORING_LIST.sort((a, b) => ((a.score < b.score)) ? 1 : -1)

      return SCORING_LIST
    },
    clearData() {
      this.isAddressReport = false
      this.isTxReport = false
      this.isEthTxReport = false
      this.isEthAddressReport = false

      this.addressInfo = {}

      this.swapSources = {}
      this.allDataSource = {}
      this.allDataSourceByOwner = {}
      this.addressRiskySources = {}
      this.addressUnknownSources = {}
      this.addressKnownSources = {}
      this.txRiskySources = {}
      this.txUnknownSources = {}
      this.txKnownSources = {}
      this.pureSources = {}
      this.currenciesByDirection = {}
      this.sourceConfigsByDirection = {}

      this.sourcesRiskPercent = 0
      this.txRiskPercent = 0
      this.addressTotalFunds = ''
      this.txTotalFunds = ''
      this.currentScoreErrorMessage = {}

      this.SET_ACTIVE_EXPOSURE_PART(this.getExposurePartOptions[0])
    },
    setTxSources({sources = [], totalAmount = 0, amountCur = null, amountCurTimestamp = null, symbol = '', message = '', currencies = {}, success = true, meta = null}, activeExposurePart) {
      if (!success) this.calcLoading = false

      if (!success && meta) {
        this.$toasted.global.info({ message: this.spamMessagesCombine({ meta }) })
      }

      if (message) {
        this.$toasted.global.error({message: `${message}`})
      }

      if (activeExposurePart === 'incoming') {
        this.totalAmount = {
          amount: totalAmount,
          amountCur,
          amountCurTimestamp,
          formattedTotalAmount: this.toComaSeparate(String(`${this.formatBtcAmount(totalAmount / (Math.pow(10, this.selectedToken.decimals || 0)), true, this.coinData.key, this.selectedToken.symbol)}`)),
          ...this.selectedToken,
        }
      }

      this.symbol = symbol

      sources = sources.map((source) => {
        const localCurrency = currencies[source.currency]
          ? { ...currencies[source.currency], decimals: currencies[source.currency].decimals || 0 }
          : {}

        return {
          ...source,
          share: sources.length === 1 ? 1 : source.share,
          currencyData: localCurrency,
          formattedAmount: this.toComaSeparate(String(this.formatterAmountValue(source.amount, localCurrency?.decimals ?? this.coinData.decimals, localCurrency?.currency || this.coinData.label))),
          depthSortValue: this.formatterDepthSortValue(source.depth)
        }
      })

      this.$set(this.currenciesByDirection, activeExposurePart, currencies)
      this.$set(this.pureSources, activeExposurePart, sources)

      if (this.sourceConfigsByDirection[activeExposurePart]) {
        this.$set(this.sourceConfigsByDirection[activeExposurePart], 'hasOnlyOneSource', sources.length === 1);
      } else {
        this.$set(this.sourceConfigsByDirection, activeExposurePart, { hasOnlyOneSource: sources.length === 1 });
      }

      this.$set(this.txRiskySources, activeExposurePart, sources.filter(source => source.listType === 'Risky sources'))
      this.$set(this.txUnknownSources, activeExposurePart,  sources.filter(source => source.listType === 'Unknown sources'))
      this.$set(this.txKnownSources, activeExposurePart, sources.filter(source => source.listType === 'Known sources'))
      this.$set(this.swapSources, activeExposurePart, sources.filter(source => source.listType === 'Swap'))

      this.swapDataFormatter(currencies, activeExposurePart)

      this.gettingEntityByOwners(this.swapSources[activeExposurePart]?.map(el => (el.owner)), 'swap', activeExposurePart)

      const groupedSourcesByType = sortingObjects(formatter(sources, 'funds.type'), sourcesSortOrderArray)

      this.$set(this.allDataSource, activeExposurePart, groupedSourcesByType.map(item => ({
        ...item,
        funds: {
          ...item.funds,
          default: Boolean(item.funds.default)
        },
        key: item.funds.type,
        tooltip: `${item.funds.type} ${formatShare(item.share)}`,
        pieValue: item.share,
        value: item.share,
        itemStyle: {color: item.funds.default ? this.findColorByTypeScore(-1) : this.findColorByTypeScore(item.funds.score)},
        formattedAmount: this.toComaSeparate(String(this.formatterAmountValue(item.amount, item?.currencyData?.decimals ?? this.coinData.decimals, item?.currencyData?.currency || this.coinData.label)))
      })))

      const groupedSourcesByOwner = sortingObjects(formatter(sources, 'owner'), sourcesSortOrderArray)

      this.$set(this.allDataSourceByOwner, activeExposurePart, groupedSourcesByOwner.map(item => ({
        ...item,
        funds: {
          ...item.funds,
          default: Boolean(item.funds.default)
        },
        key: item.owner,
        tooltip: `${item.owner} ${formatShare(item.share)}`,
        pieValue: item.share,
        value: item.share,
        itemStyle: {color: item.funds.default ? this.findColorByTypeScore(-1) : this.findColorByTypeScore(item.funds.score)},
        formattedAmount: this.toComaSeparate(String(this.formatterAmountValue(item.amount, item?.currencyData?.decimals ?? this.coinData.decimals, item.currencyData.currency || this.coinData.label)))
      })))

      this.gettingEntityByOwners(this.pureSources[activeExposurePart]?.map(el => (el.owner)), 'tx', activeExposurePart)
    },
    setAddressSources({sources = [], alerts = [], currencies = {}, value, success = true, meta = null, message = '', isMonitoring = null, selfFunding = null}, activeExposurePart) {
      if (!success && meta) {
        this.$toasted.global.info({ message: this.spamMessagesCombine({ meta }) })
      } else if (message) {
        this.$toasted.global.error({message: `${message}`})
      }

      if (!sources.length && selfFunding > 0) {
        this.$toasted.global.info({ message: this.spamMessagesCombine({ selfFunding }) })
      }

      this.$set(this.currentScoreErrorMessage, activeExposurePart, message)

      sources = sources.map((source) => {
        const localCurrency = currencies[source.currency]
          ? { ...currencies[source.currency], decimals: currencies[source.currency].decimals || 0 }
          : {}
        return {
          ...source,
          share: sources.length === 1 ? 1 : source.share,
          currencyData: localCurrency,
          formattedAmount: this.toComaSeparate(String(this.formatterAmountValue(source.amount, localCurrency?.decimals ?? this.coinData.decimals, localCurrency?.currency ?? this.coinData.label))),
          depthSortValue: this.formatterDepthSortValue(source.depth)
        }
      })

      this.$set(this.currenciesByDirection, activeExposurePart, currencies)
      this.$set(this.pureSources, activeExposurePart, sources)

      this.featureAccess('ALERTS') && alerts ? this.loadAlerts(value, alerts).then((flagsData) => {
        this.addressInfo = {
          ...this.addressInfo,
          flagsData
        }
      }) : this.addressInfo.flagsData = [];

      if (this.sourceConfigsByDirection[activeExposurePart]) {
        this.$set(this.sourceConfigsByDirection[activeExposurePart], 'hasOnlyOneSource', sources.length === 1);
      } else {
        this.$set(this.sourceConfigsByDirection, activeExposurePart, { hasOnlyOneSource: sources.length === 1 });
      }

      this.$set(this.addressRiskySources, activeExposurePart, sources.filter(source => source.listType === 'Risky sources'))
      this.$set(this.addressUnknownSources, activeExposurePart,  sources.filter(source => source.listType === 'Unknown sources'))
      this.$set(this.addressKnownSources, activeExposurePart, sources.filter(source => source.listType === 'Known sources'))
      this.$set(this.swapSources, activeExposurePart, sources.filter(source => source.listType === 'Swap'))

      this.swapDataFormatter(currencies, activeExposurePart)

      this.gettingEntityByOwners(this.swapSources[activeExposurePart]?.map(el => (el.owner)), 'swap', activeExposurePart)

      const groupedSourcesByType = sortingObjects(formatter(sources, 'funds.type'), sourcesSortOrderArray)

      this.$set(this.allDataSource, activeExposurePart, groupedSourcesByType.map(item => ({
        ...item,
        funds: {
          ...item.funds,
          default: Boolean(item.funds.default)
        },
        key: item.funds.type,
        tooltip: `${item.funds.type} ${formatShare(item.share)}`,
        pieValue: item.share,
        value: item.share,
        itemStyle: {color: item.funds.default ? this.findColorByTypeScore(-1) : this.findColorByTypeScore(item.funds.score)},
        formattedAmount: this.toComaSeparate(String(this.formatterAmountValue(item.amount, item?.currencyData?.decimals ?? this.coinData.decimals, item.currencyData.currency || this.coinData.label)))
      })))

      const groupedSourcesByOwner = sortingObjects(formatter(sources, 'owner'), sourcesSortOrderArray)

      this.$set(this.allDataSourceByOwner, activeExposurePart, groupedSourcesByOwner.map(item => ({
        ...item,
        funds: {
          ...item.funds,
          default: Boolean(item.funds.default)
        },
        key: item.owner,
        tooltip: `${item.owner} ${formatShare(item.share)}`,
        pieValue: item.share,
        value: item.share,
        itemStyle: {color: item.funds.default ? this.findColorByTypeScore(-1) : this.findColorByTypeScore(item.funds.score)},
        formattedAmount: this.toComaSeparate(String(this.formatterAmountValue(item.amount, item?.currencyData?.decimals ?? this.coinData.decimals, item.currencyData.currency || this.coinData.label)))
      })))

      this.gettingEntityByOwners(this.pureSources[activeExposurePart]?.map(el => (el.owner)), 'address', activeExposurePart)

      if (this.getActiveExposurePart.value === activeExposurePart) {
        this.addressInfo.isMonitoring = isMonitoring
      }

      // this.addressTotalFunds = appConfig.VUE_APP_SCORE_REQUEST_DIRECTION !== 2 ? totalFunds : this.calcTotalFunds(this.allDataSource.incoming, this.allDataSource.outgoing)
    },
    changeToAvailableExposurePart() {
      const availableExposurePart = this.getExposurePartOptions.find(part => !part.disabled)
      if (this.getActiveExposurePart.value !== availableExposurePart.value) {
        this.SET_ACTIVE_EXPOSURE_PART(availableExposurePart)
      }
    },
    setFieldInExposurePart(activeExposurePart, field, value) {
      const opIndex = this.getExposurePartOptions.findIndex(({ value }) => value === activeExposurePart)
      if (opIndex !== -1) {
        let options = this.getExposurePartOptions
        options[opIndex][field] = value
        this.SET_EXPOSURE_PART_OPTIONS(options)
      }
    },
    changeExposureActivePart(item) {
      this.activeExposurePart= item
    },
    changeActivePartBarValue(item) {
      this.activePartBar= item
    },
    gettingEntityByOwner(owner, isAddress = true) {
      if (owner) {
        this.getEntityList({ name: owner }).then((data) => {
          if (data?.items?.find(el => el?.name?.value?.toLowerCase() === owner?.toLowerCase())) {
            const entityId = data?.items?.find(el => el?.name?.value.toLowerCase() === owner.toLowerCase())?.id || null
            if (isAddress) {
              this.addressInfo = { ...this.addressInfo, entityId}
            } else {
              this.addressInfo.clusterData = { ...this.addressInfo.clusterData, entityId}
            }
          }
        })
      }
    },
    gettingEntityByOwners(owners, source = 'address', activeExposurePart) {
      if (owners && owners.length) {
        this.getEntityList({ names: [...new Set(owners)] }).then((data) => {

          this.allDataSourceByOwner[activeExposurePart] = this.allDataSourceByOwner[activeExposurePart]?.map(sources => {
            const entityId = data?.items?.find(el => el?.name?.value?.toLowerCase() === sources.owner?.toLowerCase())?.id || null
            return { ...sources, entityId }
          })
          this.pureSources[activeExposurePart] = this.pureSources[activeExposurePart]?.map(sources => {
            const entityId = data?.items?.find(el => el?.name?.value?.toLowerCase() === sources.owner?.toLowerCase())?.id || null
            return { ...sources, entityId }
          })

          if (source === 'address') {
            this.addressRiskySources[activeExposurePart] = this.addressRiskySources[activeExposurePart].map(source => {
              const entityId = data?.items?.find(el => el?.name?.value?.toLowerCase() === source.owner?.toLowerCase())?.id || null
              return { ...source, entityId }
            })

            this.addressKnownSources[activeExposurePart] = this.addressKnownSources[activeExposurePart].map(source => {
              const entityId = data?.items?.find(el => el?.name?.value?.toLowerCase() === source.owner?.toLowerCase())?.id || null
              return { ...source, entityId }
            })
          } else if (source === 'tx') {
            this.txRiskySources[activeExposurePart] = this.txRiskySources[activeExposurePart]?.map(source => {
              const entityId = data?.items?.find(el => el?.name?.value?.toLowerCase() === source.owner?.toLowerCase())?.id || null
              return { ...source, entityId }
            })

            this.txKnownSources[activeExposurePart] = this.txKnownSources[activeExposurePart]?.map(source => {
              const entityId = data?.items?.find(el => el?.name?.value?.toLowerCase() === source.owner?.toLowerCase())?.id || null
              return { ...source, entityId }
            })
          } else if (source === 'swap') {
            this.swapSources[activeExposurePart] = this.swapSources[activeExposurePart].map(source => {
              const entityId = data?.items?.find(el => el?.name?.value?.toLowerCase() === source.owner?.toLowerCase())?.id || null
              return { ...source, entityId }
            })
          }
        })
      }
    },
    async setAddressTokens(value) {
      this.tokensLoading = true

      await this.getAddressTokens({ address: value }).then(({ data, success }) => {
          if (!success) {
            this.$toasted.global.error({message: `${data.message}`})
          }

          this.tokensList = this.tokensSorting(data)
          // this.tokensList.unshift({ address: '', symbol: this.coinData.label, icon: getNativeCoinIcon(this.coinData.key) })

          //   this.tokensList.unshift( {address: 'all', symbol: 'All monitored', score: nativeMonitoredToken.score, isMonitored: true,})

          if (this.$route.query.token) {
            this.selectedToken = this.tokensList.find(el => el.address === this.$route.query.token)
            if (!this.selectedToken) {
              this.selectedToken = { address: '', symbol: this.coinData.label }
              this.$router.push({ name: 'report', query: { type: this.coinType, address: value, token: this.selectedToken.address } })
            }
          }

          if (this.selectedToken.address) {
            const selectedTokenIndex = this.tokensList.findIndex(el => el.address === this.selectedToken.address)
            this.moveElementToTop(this.tokensList, selectedTokenIndex)
            // const allTokenIndex = this.tokensList.findIndex(el => el.address === 'all')
            // this.moveElementToTop(this.tokensList, allTokenIndex)
          }

        }).catch(() => {
          this.calcLoading = false
        }).finally(() => {
          this.tokensLoading = false
        })
    },
    exportReportToCsv() {
      const activePart = this.getActiveExposurePart.value == 'full' ? 'incoming' : this.getActiveExposurePart.value
      if (this.isAddressReport) {
        this.csvExportReport(
          this.addressRiskySources[activePart],
          this.addressKnownSources[activePart],
          this.addressUnknownSources[activePart],
          this.addressInfo,
          this.addressTotalFunds,
          'address',
          this.sourcesRiskPercent,
          `${this.search}_report`)
      } else if (this.isTxReport) {
        this.csvExportReport(
          this.txRiskySources[activePart],
          this.txKnownSources[activePart],
          this.txUnknownSources[activePart],
          this.txInfo,
          this.txTotalFunds,
          'tx_hash',
          this.txRiskPercent,
          `${this.search}_report`)
      }
    },
  }
}
</script>

<style>
.save-csv-button {
  position: fixed;
  bottom: 50px;
  right: 180px;
  border: none;
  border-radius: 3px;
  color: var(--white);
  cursor: pointer;
  font-weight: bold;
  font-size: 13px;
  line-height: 1.29;
  min-width: 100px;
  padding: 6px;
  text-align: center;
  text-transform: uppercase;
  z-index: 10;
}

.report-page__wrapper {
  background: var(--cotton-grey-f-9);
}

.report__wrapper {
  border-radius: 3px;
  padding-bottom: 70px;
}

.report-search-control__wrapper {
  display: flex;
  flex-direction: column;
  padding: 24px;
  background-color: var(--white);
  border-radius: 16px;
  border: 1px solid var(--Accent-Coton-Grey);
  margin-bottom: 24px;
}

.report-block__header {
  align-items: center;
  display: flex;
  font-size: 18px;
  padding: 14px;
  font-weight: 600;
  border: 1px solid #fff;
  border-bottom: 0;
}

.report-block__header--risk {
  background: var(--risk);
}

.report-block__header--known {
  background: var(--known);
}

.report-block__header--unknown {
  background: var(--unknown);
}

.circle-progress svg {
  width: 100%;
}

/*.circle-progress svg path {*/
/*  stroke-width: px;*/
/*}*/

.a-circle-progress-wrapper .circle-progress {
  flex-direction: column;
}

.a-circle-progress-wrapper .circle-progress .progress-content .inner-default-percentage {
 align-items: flex-end;
}

.save-button {
  position: fixed;
  bottom: 50px;
  right: 50px;
  border: none;
  border-radius: 3px;
  color: var(--white);
  cursor: pointer;
  font-weight: bold;
  font-size: 13px;
  line-height: 1.29;
  min-width: 100px;
  padding: 6px;
  text-align: center;
  text-transform: uppercase;
  z-index: 10;
  opacity: 1;
}

.report-block-wrap {
  padding: 5px;
  border: 1px solid var(--pale-grey);
  border-radius: 5px;
}

.report-block__header--sof {
  background: rgba(240, 242, 249, 1);
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  margin: 12px 0;
}

.report__search-bar {
  display: flex;
  /* padding: 24px; */
}
.report__search-bar .coin-select-base {
  margin-right: 16px !important;
}
.vs__dropdown-menu {
  min-width: 150px;
}

@media (max-width: 767px) {
  .search-box {
    border-left: 2px solid var(--dark-grey-d-3);
  }

  .report__search-bar .coin-select-base {
    margin-right: 0 !important;
  }

  .report__wrapper {
    padding: 12px 8px 24px 8px;
  }

  .save-button, .save-csv-button {
    position: relative;
    bottom: unset;
    right: unset;
  }
  .report__search-bar {
    padding: 8px;
  }
  .report-search-control__wrapper {
    display: flex;
    flex-direction: column;
    padding: 8px;
    margin-bottom: 16px;
  }

}
</style>
