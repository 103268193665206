<template>
  <transition
    appear
    name="fade"
  >
    <svg
      fill="none"
      height="100%"
      viewBox="0 0 102 36"
      width="100%"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M102 36L51 -2.22928e-06L0 36L102 36Z"
        fill="url(#paint0_radial_7481_116507)"
      />
      <defs>
        <radialGradient
          id="paint0_radial_7481_116507"
          cx="0"
          cy="0"
          gradientTransform="translate(51 -3.92698e-06) rotate(90) scale(36 56.9498)"
          gradientUnits="userSpaceOnUse"
          r="1"
        >
          <stop
            :stop-color="riskColor"
            stop-opacity="0.5"
          />
          <stop
            offset="1"
            :stop-color="riskColor"
            stop-opacity="0"
          />
        </radialGradient>
      </defs>
    </svg>
  </transition>
</template>
<script>
// Utils
import { findColorByTypeScore2 } from '@/utils/cytoskape-ui-rules'
export default {
  props: {
    score: {
      type: [Number, String],
      default: 33,
    },
  },
  computed: {
    riskColor() {
      return this.findColorByTypeScore2(this.score)
    },
  },
  methods: {
    findColorByTypeScore2,
  },
}
</script>

<style scoped>
svg {
  display: block;
}
</style>
