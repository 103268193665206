<template>
  <div class="gl-circle-chart__wrapper-mask">
    <div class="gl-circle-chart__wrapper">
      <div class="gl-circle-chart__info">
        <div class="gl-circle-chart__score">
          <VueSkeletonLoader
            v-if="loading"
            animation="fade"
            class="gl-circle-chart__score-loader"
            type="circle"
          />
          <div v-else>
            {{ formattedScore }}
          </div>
        </div>
        <div class="gl-circle-chart__text">
          <VueSkeletonLoader
            v-if="loading"
            animation="fade"
            class="gl-circle-chart__text-loader"
            type="circle"
          />
          <div v-else>
            {{ calculateRiskLevel(score) }}
          </div>
        </div>
      </div>
      <div class="gl-circle-chart-container">
        <svg
          height="220"
          viewBox="0 0 220 220"
          width="220"
          xmlns="http://www.w3.org/2000/svg"
        >
          <circle
            cx="110"
            cy="110"
            fill="none"
            r="92"
            stroke="#1149FF"
            stroke-width="10"
          />

          <circle
            ref="progressCircle"
            cx="110"
            cy="110"
            fill="none"
            r="98"
            stroke="#FF2A00"
            stroke-dasharray="628"
            :stroke-dashoffset="dashOffset"
            stroke-width="22"
            transform="rotate(-90 110 110)"
          />
        </svg>
      </div>
    </div>
  </div>
</template>

<script>
// components
import VueSkeletonLoader from 'skeleton-loader-vue'

// utils
import { calculateRiskLevel } from '@/utils/risk-formatter'

export default {
  components: {
    VueSkeletonLoader,
  },
  props: {
    loading: {
      type: Boolean,
      default: false,
    },
    score: {
      type: [Number, String],
      default: 33,
    },
    animationDuration: {
      type: [Number, String],
      default: 600,
    },
  },
  data() {
    return {
      innerScore: 0,
    }
  },
  computed: {
    dashOffset() {
      const circleCircumference = 2 * Math.PI * 100
      return circleCircumference * (1 - this.innerScore / 102)
    },
    formattedScore() {
      return Number(this.innerScore).toFixed(0)
    },
  },
  watch: {
    score: {
      handler(score) {
        this.innerScore = score
        this.animateScore(score)
      },
      immediate: true,
    },
  },
  methods: {
    calculateRiskLevel,
    animateScore(targetScore) {
      const start = performance.now()
      const duration = this.animationDuration
      const animate = currentTime => {
        const elapsedTime = currentTime - start
        const progress = Math.min(elapsedTime / duration, 1)
        this.innerScore = progress * targetScore

        if (progress < 1) {
          requestAnimationFrame(animate)
        }
      }

      requestAnimationFrame(animate)
    },
  },
}
</script>
<style>
.gl-circle-chart__wrapper {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 280px;
  height: 280px;
  background: var(--white);
  border-radius: 280px;
  box-shadow: 0px 4px 12px -4px rgba(14, 11, 61, 0.2);
  outline: 20px solid #f5f5f550;
}
.gl-circle-chart-container {
  position: absolute;
  inset: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}
.gl-circle-chart__info {
  display: flex;
  flex-direction: column;
  width: fit-content;
}
.gl-circle-chart__score {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 52px;
  font-weight: 600;
  line-height: 52px;
  border-bottom: 1px solid var(--space-cadet);
}
.gl-circle-chart__text {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: 32px;
  text-transform: uppercase;
}

.gl-circle-chart__score-loader {
  width: 50px !important;
  height: 40px !important;
  margin-bottom: 8px;
}
.gl-circle-chart__text-loader {
  width: 100px !important;
  height: 24px !important;
  margin-top: 8px;
}
</style>
