<template>
  <div class="score-chart__wrapper">
    <div class="score-chart__start-wrapper">
      ddd
    </div>
    <div class="score-chart__middle-wrapper">
      <div class="score-chart__middle-left">
        <div class="score-chart__side-svg">
          <RiskArrowLeftIcon
            v-show="!loading"
            :score="100"
          />
        </div>
      </div>
      <div class="score-chart__middle-center">
        <div class="score-chart__middle-top">
          <div class="score-chart__hint-text">
            Profile Risk
          </div>
          <div class="flex justify-center">
            <GlBadgeInfo
              :entity-id="'22'"
              text="Mining Service"
              :value="22"
            />
          </div>

          <div class="score-chart__middle-svg">
            <RiskArrowTopIcon
              v-show="!loading"
              :score="21"
            />
          </div>
        </div>
        <div class="score-chart-container">
          <GlScoreChart
            :loading="loading"
            :score="score"
          />
        </div>
        <div class="score-chart__middle-bottom">
          <div class="score-chart__middle-svg">
            <RiskArrowBottomIcon
              v-show="!loading"
              :score="55"
            />
          </div>
          <div class="flex justify-center">
            <GlBadgeInfo
              text="P2P Exchange"
              :value="52"
            />
          </div>
          <div class="score-chart__hint-text">
            Address Risk
          </div>
        </div>
      </div>

      <div class="score-chart__middle-right">
        <div class="score-chart__side-svg">
          <RiskArrowRightIcon
            v-show="!loading"
            :score="50"
          />
        </div>
      </div>
    </div>
    <div class="score-chart__end-wrapper">
      ddd
    </div>
  </div>
</template>

<script>
// Components
import GlScoreChart from '@/components/charts/gl-circle-chart'
import RiskArrowTopIcon from '@/assets/svg/risk/RiskArrowTopIcon'
import RiskArrowBottomIcon from '@/assets/svg/risk/RiskArrowBottomIcon'
import RiskArrowRightIcon from '@/assets/svg/risk/RiskArrowRightIcon'
import RiskArrowLeftIcon from '@/assets/svg/risk/RiskArrowLeftIcon'
import GlBadgeInfo from '@/components/gl-badge-info'

export default {
  components: {
    GlScoreChart,
    RiskArrowTopIcon,
    RiskArrowBottomIcon,
    RiskArrowRightIcon,
    RiskArrowLeftIcon,
    GlBadgeInfo,
  },
  props: {
    loading: {
      type: Boolean,
      default: false,
    },
    score: {
      type: [Number, String],
      default: 33,
    },
  },
}
</script>
<style>
.score-chart__wrapper {
  display: flex;
}
.score-chart__start-wrapper {
  width: 30%;
}
.score-chart__end-wrapper {
  width: 30%;
}
.score-chart__middle-wrapper {
  width: 40%;
  display: flex;
  justify-content: center;
  gap: 70px;
}

.score-chart__middle-top,
.score-chart__middle-bottom {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 8px;
  position: relative;
  z-index: 1;
}
.score-chart__middle-top {
}
.score-chart__middle-bottom {
}

.score-chart__middle-left,
.score-chart__middle-right {
  display: flex;
  justify-content: center;
  align-items: center;
}

.score-chart__hint-text {
  color: var(--Accent-Dark-Grey);
  text-align: center;
  font-size: 12px;
  font-weight: 500;
  line-height: 16px;
}
.score-chart__middle-svg {
  height: 36px;
  width: 102px;
}
.score-chart__side-svg {
  width: 84px;
  height: 240px;
}

.score-chart-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  /* background: grey; */
}
.score-chart__top {
  width: 30px;
}
</style>
