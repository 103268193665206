import { render, staticRenderFns } from "./RiskArrowLeftIcon.vue?vue&type=template&id=46544436&scoped=true"
import script from "./RiskArrowLeftIcon.vue?vue&type=script&lang=js"
export * from "./RiskArrowLeftIcon.vue?vue&type=script&lang=js"
import style0 from "./RiskArrowLeftIcon.vue?vue&type=style&index=0&id=46544436&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "46544436",
  null
  
)

export default component.exports