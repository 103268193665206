<template>
  <transition
    appear
    name="fade"
  >
    <svg
      fill="none"
      height="100%"
      viewBox="0 0 36 102"
      width="100%"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0 102L36 51L0 0L0 102Z"
        fill="url(#paint0_radial_7481_144588)"
      />
      <defs>
        <radialGradient
          id="paint0_radial_7481_144588"
          cx="0"
          cy="0"
          gradientTransform="translate(36 51) rotate(180) scale(36 56.9498)"
          gradientUnits="userSpaceOnUse"
          r="1"
        >
          <stop
            :stop-color="riskColor"
            stop-opacity="0.5"
          />
          <stop
            offset="1"
            :stop-color="riskColor"
            stop-opacity="0"
          />
        </radialGradient>
      </defs>
    </svg>
  </transition>
</template>
<script>
// Utils
import { findColorByTypeScore2 } from '@/utils/cytoskape-ui-rules'
export default {
  props: {
    score: {
      type: [Number, String],
      default: 33,
    },
  },
  computed: {
    riskColor() {
      return this.findColorByTypeScore2(this.score)
    },
  },
  methods: {
    findColorByTypeScore2,
  },
}
</script>

<style scoped>
svg {
  display: block;
}
</style>
